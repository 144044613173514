import { Component, OnInit, Inject, Renderer2, ElementRef, OnDestroy, HostListener } from '@angular/core';
import * as Aos from 'aos';

@Component({
    selector: 'app-nucleoicons',
    templateUrl: './nucleoicons.component.html',
    styleUrls: ['./nucleoicons.component.scss']
})


export class NucleoiconsComponent implements OnInit, OnDestroy {
    // counter For Projects
    projectcont:number = 0;    
    projectcountstop:any = setInterval(()=>{
        this.projectcont++;
        if(this.projectcont==350){
            clearInterval(this.projectcountstop);
        }
    },10)
    // Counter For Coustomer
    customercount:number = 0;
    customercountstop:any = setInterval(()=>{
        this.customercount++;
        if(this.customercount==350){
            clearInterval(this.customercountstop);
        }
    },10)
    // Counter For Service
    servicecount:number = 0;
    servicecountstop:any = setInterval(()=>{
        this.servicecount++;
        if(this.servicecount==350){
            clearInterval(this.servicecountstop);
        }
    },10)
    constructor( private element : ElementRef) {}

    ngOnInit() {
        const body = document.getElementsByTagName('app-nucleoicons')[0];
        let navbar = document.getElementsByTagName('app-navbar')[0].children[0];
        navbar.classList.add('navbar-hidden');
        body.classList.add('demo-icons');
        Aos.init();
    }

    ngOnDestroy(){
        let navbar = document.getElementsByTagName('app-navbar')[0].children[0];
        navbar.classList.remove('navbar-hidden');
    }

      scrollHandler(event){
    console.log(event);
    console.log('now you are scrolling');
  }
}


